const contacts = {
  cl_call: 9769601410,
  cl_call_alt: 8076863156,
  cl_whatsapp: 9769601410,
  cl_whatsapp_alt: 8076863156,
  cl_email: "info@ivaasoutsourcing.com",
  cl_facebook: "",
  cl_instagram: "https://www.instagram.com/ivaas_outsourcing/",
  cl_linkedin: "",
  cl_twitter: "",
  cl_youtube: "",
};

export { contacts };
